import { FC } from 'react'
import { NextSeo, NextSeoProps } from 'next-seo'
import { OpenGraph } from 'next-seo/lib/types'

export type PageContainerProps = {
  seoProps?: NextSeoProps
}
export const PageContainer: FC<PageContainerProps> = ({
  children,
  seoProps,
  ...rest
}) => {
  const openGraph: OpenGraph = {
    ...(seoProps?.openGraph || {}),
    description:
      seoProps?.description ||
      'Join our amazing community of women in improving your physical and mental health, all from the comfort of your own home.',
    images: seoProps?.openGraph?.images || [
      {
        url: 'https://builtbybecky.com/images/preview.png',
      },
    ],
    site_name: 'Built by Becky',
    title:
      seoProps?.title ||
      'Built by Becky - helping women feel amazing at any age',
    type: 'website',
  }
  return (
    <>
      <NextSeo {...seoProps} openGraph={openGraph} />
      {children}
    </>
  )
}

PageContainer.displayName = 'PageContainer'
export default PageContainer
