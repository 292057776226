import Input, { Input as InputType } from './Input'
import { FC, ReactNode } from 'react'
import classNames from 'classnames'

export interface InputGroup {
  inputProps?: Partial<InputType>
  className?: string
  icon?: ReactNode
  title?: string
  component?: ReactNode
  showValidation?: boolean
  textarea?: boolean
  isValid?: boolean
  required?: boolean
  id?: string
  onBlur?: (e: FocusEvent) => void
  disabled?: boolean
  errorMessage?: string
  name?: string
  value?: string
  defaultValue?: string
  onChange?: (e: any) => void
  type?: string
  placeholder?: string
  iconColour?: string
  accessibilityLabel?: string
}

const InputGroup: FC<InputGroup> = (
  {
    name,
    onBlur,
    errorMessage,
    inputProps = {},
    isValid,
    className,
    title,
    component,
    textarea,
    required,
    disabled,
    value,
    id: _id,
    defaultValue,
    onChange,
    type,
    placeholder,
    icon,
    showValidation,
    iconColour,
    accessibilityLabel,
  },
  props,
) => {
  //   Should I use useRef to connect focus with input?
  //   const focus = () => {
  //     input.focus();
  //   };

  const id = _id || inputProps.id || inputProps.name
  return (
    <div className={classNames(`form-group`, className)}>
      {title ? (
        <label className='form-label' htmlFor={id}>
          {title}
          {required && <span className='text-danger'>*</span>}
        </label>
      ) : null}

      {errorMessage && (
        <span>
          <span
            id={inputProps.name ? `${inputProps.name}-error` : ''}
            className='text-danger'
          >
            {errorMessage}
          </span>
        </span>
      )}

      <>
        {component ? (
          component
        ) : (
          <Input
            // ref={(c) => (this.input = c)}
            name={name}
            textarea={textarea}
            onBlur={onBlur}
            icon={icon}
            showValidation={showValidation}
            {...(inputProps || {})}
            isValid={isValid}
            disabled={disabled}
            value={value}
            data-test={['data-test']}
            defaultValue={defaultValue}
            onChange={onChange}
            type={type || 'text'}
            id={id}
            placeholder={placeholder}
            iconColour={iconColour}
            aria-label={accessibilityLabel}
          />
        )}
      </>
    </div>
  )
}

InputGroup.displayName = 'InputGroup'
export default InputGroup
