import { FC, useEffect, useState } from 'react'
import cn from 'classnames'

export type AccoutCreationStep = 'plan' | 'user' | 'payment'

type AccoutCreationStepperProps = {
  onSelect?: (menu: AccoutCreationStep) => void
  stepActive: AccoutCreationStep
  disabledMenu?: AccoutCreationStep
  registrationComplete?: boolean
}

type StepItemProps = {
  step: number
  isActive?: boolean
  onClick?: () => void
  complete?: boolean
}

const StepItem: React.FC<StepItemProps> = ({
  complete,
  isActive,
  onClick,
  step,
}) => {
  return (
    <li
      onClick={!complete ? onClick : () => null}
      className={cn(
        'stepper-step',
        'cursor-pointer',
        'text-small',
        'fw-bold',
        'd-flex',
        'justify-content-center',
        'align-items-center',
        'flex-column',
        'mb-4',
        {
          'bg-blackLighter opacity-25 text-xs text-regular': !isActive,
          'bg-pink': isActive,
        },
      )}
    >
      {complete ? (
        <span className='fa fa-check text-white' />
      ) : (
        <span>{step}</span>
      )}
    </li>
  )
}

const AccoutCreationStepper: FC<AccoutCreationStepperProps> = ({
  disabledMenu,
  onSelect,
  registrationComplete,
  stepActive,
}) => {
  const [activeStep, setActiveStep] = useState<AccoutCreationStep>(stepActive)

  useEffect(() => {
    setActiveStep(stepActive)
    onSelect?.(stepActive)
  }, [stepActive])

  const setSelectedMenu = (menu: AccoutCreationStep) => {
    setActiveStep(menu)
    onSelect?.(menu)
  }
  return (
    <div className='mt-21 justify-content-center d-flex px-2'>
      <ul
        className='gap-2-half'
        style={{
          listStyle: 'none',
        }}
      >
        <StepItem
          complete={registrationComplete}
          isActive={activeStep === 'plan'}
          onClick={() => {
            disabledMenu !== 'plan' && setSelectedMenu('plan')
          }}
          step={1}
        />

        <StepItem
          complete={registrationComplete}
          step={2}
          onClick={() => {
            disabledMenu !== 'user' && setSelectedMenu('user')
          }}
          isActive={activeStep === 'user'}
        />

        <StepItem
          step={3}
          onClick={() => {
            disabledMenu !== 'payment' && setSelectedMenu('payment')
          }}
          isActive={activeStep === 'payment'}
        />
      </ul>
    </div>
  )
}

export default AccoutCreationStepper
