import cn from 'classnames'
import { FC } from 'react'
import { Utils } from 'common/utils'
import * as React from 'react'

interface Messages {
  /** The error message to be displayed, replaces \n */
  className?: string
  icon?: string
  'data-test'?: string
}

//Generic error message
const Message: FC<Messages> = ({
  children,
  className,
  'data-test': dataTest,
  icon,
}) => {
  if (!children) {
    return null
  }

  return (
    <>
      <div data-test={dataTest} className={`alert ${className || ''}`}>
        <div className='flex-row no-wrap'>
          {icon && <span className={cn({ icon: true }, 'me-3', icon)} />}
          <span data-test='message'>{children}</span>
        </div>
      </div>
    </>
  )
}

//Default message added alert-danger
export const ErrorMessage: FC<Messages> = ({ className, ...props }) => (
  <Message
    {...props}
    icon={props.icon || 'fas fa-exclamation-circle'}
    className={cn(className, 'alert-danger text-danger')}
  >
    {Utils.parseError(props.children)}
  </Message>
)

//Default message added alert-danger
export const WarningMessage: FC<Messages> = ({ className, ...props }) => (
  <Message {...props} className={cn(className, 'alert-warning')}>
    <div className='d-flex align-items-center'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={23}
        height={26}
        viewBox={'0 0 23 26'}
        fill='none'
        className='me-1'
        {...props}
      >
        <path
          fill='#fff'
          d='M12.672 14.969c-.065.716-.472 1.107-1.22 1.172a1.13 1.13 0 0 1-.83-.342 1.13 1.13 0 0 1-.343-.83c0-.326.114-.602.342-.83a1.13 1.13 0 0 1 .83-.342c.749.065 1.156.456 1.22 1.172Zm-.39-3.125c-.033.488-.31.748-.83.781-.457-.033-.717-.293-.782-.781V6.375c.065-.488.325-.749.781-.781.521.032.798.293.83.781v5.469ZM10.67.906c.065-.488.325-.748.781-.781.521.033.798.293.83.781v.83c2.018.228 3.679 1.058 4.98 2.49 1.335 1.433 2.019 3.19 2.052 5.274v1.416c.032 2.214.813 4.118 2.343 5.713l.147.098c.423.423.634.944.634 1.562 0 .619-.211 1.123-.634 1.514-.39.423-.895.634-1.514.634H2.711c-.619 0-1.123-.211-1.514-.634-.423-.39-.635-.895-.635-1.514 0-.618.212-1.14.635-1.562l.147-.098c1.53-1.595 2.311-3.5 2.343-5.713V9.5c.033-2.083.717-3.841 2.051-5.273 1.302-1.433 2.963-2.263 4.98-2.49l-.048-.83Zm.781 2.344c-1.758.033-3.222.635-4.394 1.807C5.885 6.26 5.267 7.742 5.2 9.5v1.416c-.032 2.637-.96 4.9-2.783 6.787l-.098.147a.85.85 0 0 0-.244.44c.065.357.277.552.635.585h17.578c.358-.033.553-.228.586-.586a.607.607 0 0 0-.195-.44l-.098-.146c-1.855-1.888-2.8-4.15-2.832-6.787V9.5c-.033-1.758-.651-3.239-1.855-4.443-1.172-1.172-2.653-1.774-4.444-1.807Zm0 20.313c.749-.033 1.253-.375 1.514-1.026.195-.456.537-.618 1.025-.488.423.195.57.52.44.976a3.261 3.261 0 0 1-1.123 1.514c-.521.39-1.14.586-1.856.586-.683 0-1.27-.195-1.758-.586a3.261 3.261 0 0 1-1.123-1.514c-.13-.455.017-.78.44-.976.488-.13.83.032 1.025.488.26.651.733.993 1.416 1.026Z'
        />
      </svg>
      {props.children}
    </div>
  </Message>
)

// Default message added alert-success
export const SuccessMessage: FC<Messages> = ({ className, ...props }) => (
  <Message
    {...props}
    icon={props.icon || 'fas fa-check-circle'}
    className={cn(className, 'alert-success text-success')}
  />
)

Message.displayName = 'ErrorMessage'
