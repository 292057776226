import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'

export const planService = service
  .enhanceEndpoints({ addTagTypes: ['Plan'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPlans: builder.query<Res['plans'], Req['getPlans']>({
        providesTags: [{ id: 'LIST', type: 'Plan' }],
        query: () => ({
          url: `subscription/`,
        }),
      }),
      // END OF ENDPOINTS
    }),
  })

export async function getPlans(
  store: any,
  data: Req['getPlans'],
  options?: Parameters<typeof planService.endpoints.getPlans.initiate>[1],
) {
  store.dispatch(planService.endpoints.getPlans.initiate(data, options))
  return Promise.all(store.dispatch(planService.util.getRunningQueriesThunk()))
}
// END OF FUNCTION_EXPORTS

export const {
  useGetPlansQuery,
  // END OF EXPORTS
} = planService

/* Usage examples:
const { data, isLoading } = useGetPlansQuery({ id: 2 }, {}) //get hook
const [createPlans, { isLoading, data, isSuccess }] = useCreatePlansMutation() //create hook
planService.endpoints.getPlans.select({id: 2})(store.getState()) //access data from any function
*/
