import React, { FC, ReactElement } from 'react'

import ReactTooltip, { TooltipProps } from 'react-tooltip'
import { Utils } from 'common/utils'

type _TooltipProps = {
  content: ReactElement | string | undefined
  place?: TooltipProps['place'] | undefined
  showIcon?: boolean
}

const Tooltip: FC<_TooltipProps> = ({ children, content, place, showIcon }) => {
  const id = Utils.GUID()

  if (!content) {
    return <>{children}</>
  }
  return (
    <>
      {children ? (
        <span data-for={id} data-tip>
          {children}
          {showIcon && (
            <span className='ms-1 fa fa-info-circle' data-for={id} data-tip />
          )}
        </span>
      ) : (
        <span className='fa fa-info-circle' data-for={id} data-tip />
      )}
      {/*// @ts-ignore*/}
      <ReactTooltip
        html
        className='rounded'
        id={id}
        place={place || 'top'}
        type='dark'
        effect='solid'
      >
        {content}
      </ReactTooltip>
    </>
  )
}

export default Tooltip
