import { FC } from 'react'
import { AccoutCreationStep } from './AccountCreationStepper'
import cn from 'classnames'

interface CollapsibleMenuProps {
  title: string
  menuId: AccoutCreationStep
  onClick?: () => void
  isOpen: boolean
  disabled?: boolean
  children?: React.ReactNode
  accessibilityLabel?: string
}

const CollapsibleMenu: FC<CollapsibleMenuProps> = ({
  accessibilityLabel,
  children,
  disabled,
  isOpen,
  menuId,
  onClick,
  title,
}) => {
  return (
    <>
      <button
        aria-label={accessibilityLabel}
        className={cn(
          'flex-row justify-content-between align-items-center w-100 bg-transparent border-0 text-white mt-4-half',
        )}
        style={
          disabled
            ? {
                cursor: 'not-allowed',
              }
            : undefined
        }
        data-bs-toggle='collapse'
        data-bs-target={`#collapseMenu-${menuId}`}
        aria-expanded={true}
        aria-controls={`collapseMenu-${menuId}`}
        onClick={!disabled ? onClick : () => null}
      >
        <h6
          className={cn('mb-0', {
            'text-darkGrey': disabled,
          })}
        >
          {title}
        </h6>
        {!disabled && isOpen ? (
          <span className='fa fa-chevron-down' />
        ) : (
          <span className='fa fa-chevron-down' />
        )}
      </button>

      {!!children && (
        <div
          className={`collapse mt-3-half ${isOpen ? 'show' : ''}`}
          id={`collapseMenu-${menuId}`}
        >
          {children}
        </div>
      )}
    </>
  )
}

export default CollapsibleMenu
