import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  KeyboardEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import cn from 'classnames'
import { Utils } from 'common/utils'

export const sizeClassNames = {
  default: '',
  large: 'form-control-lg',
  small: 'form-control-sm',
}

export interface Input {
  textarea?: boolean
  isValid?: boolean
  placeholder?: string
  inputClassName?: string
  name?: string
  icon?: ReactNode
  id?: string
  type?: string
  textButton?: string
  className?: string
  iconColour?: string
  touched?: boolean
  value?: string
  showValidation?: boolean
  onIconClick?: () => void
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onKeyDown?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
  disabled?: boolean
  'data-test'?: string
  size?: keyof typeof sizeClassNames
}

const Input: FC<Input> = ({
  children,
  className,
  disabled,
  icon,
  iconColour,
  id,
  inputClassName,
  isValid = true,
  name,
  onBlur,
  onFocus,
  onIconClick,
  onKeyDown,
  placeholder = ' ',
  showValidation,
  size = 'default',
  textarea,
  touched,
  type,
  value,
  ...rest
}) => {
  const [shouldValidate, setShouldValidate] = useState(!!showValidation)

  useEffect(() => {
    setShouldValidate(!!showValidation)
  }, [showValidation])
  const [isFocused, setIsFocused] = useState(false)
  const ref = useRef<HTMLInputElement | HTMLTextAreaElement>()
  const focusHandler: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    setIsFocused(true)
    onFocus && onFocus(e)
  }

  // Is it element important? Should I use UseRef hook?
  // const focus = () => {
  //   this.input.focus();
  // };

  const _onKeyDown: KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    if (Utils.keys.isEscape(e)) {
      ref.current?.blur()
    }
    onKeyDown && onKeyDown(e)
  }

  const blur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    e,
  ) => {
    setShouldValidate(true)
    setIsFocused(false)
    onBlur && onBlur(e)
  }

  const classNameHandler = cn(
    'position-relative',
    {
      focused: isFocused,
      invalid: (shouldValidate || touched) && !isValid,
    },
    className,
  )

  const combinedInputClassName = cn(
    {
      error: !(shouldValidate || touched) && !isValid,
      'form-control': true,
      'form-control--with-icon': !!icon || type === 'password',
      'form-control--with-icon-right': type == 'password',
    },
    sizeClassNames[size],
    inputClassName,
  )
  const [showPassword, setShowPassword] = useState<boolean>(false)
  return (
    <div className={classNameHandler}>
      {textarea ? (
        <>
          <textarea
            name={name}
            placeholder={placeholder}
            {...rest}
            id={id}
            // @ts-ignore
            ref={ref}
            onFocus={focusHandler}
            onKeyDown={_onKeyDown}
            onBlur={blur}
            value={value}
            className={combinedInputClassName}
          />
        </>
      ) : (
        <>
          <input
            disabled={disabled}
            name={name}
            type={type === 'password' && showPassword ? '' : type}
            {...rest}
            id={id}
            // @ts-ignore
            ref={ref}
            onFocus={focusHandler}
            onKeyDown={_onKeyDown}
            onBlur={blur}
            value={value}
            placeholder={placeholder}
            className={combinedInputClassName}
          />
          {type == 'password' && (
            <>
              <i
                data-test={`${rest['data-test']}-icon`}
                className={cn('input-icon', icon)}
              >
                <span className='fa fa-lock' />
              </i>

              <i
                data-test={`${rest['data-test']}-icon`}
                onClick={() => {
                  if (type === 'password') {
                    setShowPassword(!showPassword)
                  } else {
                    onIconClick ? onIconClick() : ref?.current?.focus()
                  }
                }}
                className={cn(
                  'text-turquoise',
                  'input-icon',
                  'input-icon--right',
                  {
                    'fas fa-eye': type === 'password' && !showPassword,
                    'fas fa-eye-slash': type === 'password' && showPassword,
                    icon: true,
                  },
                  icon,
                )}
              />
            </>
          )}
          {!!icon && (
            <i
              data-test={`${rest['data-test']}-icon`}
              onClick={() => {
                if (type === 'password') {
                  setShowPassword(!showPassword)
                } else {
                  onIconClick ? onIconClick() : ref?.current?.focus()
                }
              }}
              className={cn('input-icon', icon)}
            >
              {icon}
            </i>
          )}
        </>
      )}
      {children && children}
    </div>
  )
}

Input.displayName = 'Input'
export default Input
